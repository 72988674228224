import React from "react";
import PagesLayout from "../layouts/PagesLayout";
const FreeDataPage = () => {
  return (
    <PagesLayout>
      <div>THIS IS FREE DATA PAGE</div>
    </PagesLayout>
  );
};

export default FreeDataPage;
